const Timer = {
  timerOn: true,
  init: function (remaining) {
    $('#resend_otp').addClass('d-none');
    $('#timer_box').removeClass('d-none');
    var m = Math.floor(remaining / 60);
    var s = remaining % 60;

    m = m < 10 ? '0' + m : m;
    s = s < 10 ? '0' + s : s;
    document.getElementById('timer').innerHTML = m + ':' + s;
    remaining -= 1;

    if (remaining >= 0 && this.timerOn) {
      setTimeout(function () {
        Timer.init(remaining);
      }, 1000);
      return;
    }

    if (!this.timerOn) {
      // Do validate stuff here
      return;
    }

    // Do timeout stuff here
    $('#resend_otp').removeClass('d-none');
    $('#timer_box').addClass('d-none');
  }
}

export { Timer }



