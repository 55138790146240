require("@rails/ujs").start();
require("turbolinks").start();
import { Helpers } from "src/helpers";
import { Utility } from "src/utility";
import { Verification } from "src/verification_options";
import { Timer } from "src/timer";


var jQuery = require('jquery')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;
window.bootstrap = require('bootstrap');
window.Helpers = Helpers
window.Utility = Utility
window.Verification = Verification
window.Timer = Timer

Utility.showToast();
Verification.init();
Helpers.initPasswordToggle();
