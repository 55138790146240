const Verification = {
  init: function (){
    $("#verification_options").on( "click", 'a', function() {
      if (this.id == 'email_otp_verification_option') { return }
      Verification.perform_action_for(this.id)
    });

    $( "body" ).on( "click", "#choose_verification_options", function() {
      Verification.show_options();
    });
  },

  perform_action_for: function (option_link_id){
    $('.verification_sections').addClass('d-none');
    $('#' + option_link_id.replace('_option', '')).removeClass('d-none');
  },

  show_options: function (){
    $("form[id|='login-form']").each(function () { this.reset(); });
    $('.verification_sections').addClass('d-none');
    $('#verification_options').removeClass('d-none');
  }
}

export { Verification }
